<template>
  <div>
    <b-card title="Quản lý khối">
      <div class="top-page">
        <b-row>
          <b-col lg="4">
            <b-form-group label="Phòng ban">
              <v-select placeholder="Chọn phòng ban" @input="getListSchoolBlock" v-model="modelSchoolBlockSearch.parentId" :options="listDepartment" label="organizationBranchName" :reduce="(department) => department.id">
                 <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Tên khối">
              <b-form-input v-model="modelSchoolBlockSearch.name" @keyup.enter="getListSchoolBlock" placeholder="Nhập tên khối để tìm kiếm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group class="custom-button-search">
              <b-button @click="refreshList" variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <div class="add-new-school-block">
              <b-button @click="getFormAddSchoolBlock"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">Thêm mới</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="data-table-schoolblock">
        <span class="custom-span">Có tổng số {{totalCountPage}} bản ghi</span>
        <b-row>
          <b-col lg="12">
            <b-table responsive
                     :items="listSchoolBlock"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :fields="fields"
                     class="mb-0">
              <template #cell(index)="data">
                <span>{{data.index + 1}}</span>
              </template>
              <template #cell(hanhdong)="data">
                <b-button @click="getFormUpdateSchoolBlock(data.item.id)" v-b-tooltip.hover.top="'Cập nhật'" variant="flat-primary" class="btn-icon"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button v-b-tooltip.hover.top="'Xóa'" @click="deleteSchoolBlock(data.item)"
                          variant="flat-danger"
                          class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
           <div class="pagination-schoolblock">
             <b-pagination v-model="currentPage"
               :per-page="perPage"
               hide-goto-end-buttons
               :total-rows="row"></b-pagination>
           </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal ref="modal-school-block"
             size="sm"
             hide-footer
             hide-header>
      <div class="header-modal-school-block">
        <h3>{{isSchoolBlock? 'Cập nhật': 'Thêm mới'}} khối </h3>
      </div>
      <div class="main-body-school-block">
        <validation-observer ref="modelSchoolBlock">
            <b-form-group v-if="!isSchoolBlock">
              <validation-provider #default="{ errors }" name="Phòng ban" rules="required">
              <label class="font-weight-bold font-size-label-custom" for="blockSchool">Phòng ban <span class="text-danger">(*)</span></label>
              <v-select class="custom-v-select" menu-props="auto" placeholder="Chọn phòng ban" v-model="modelSchoolBlock.parentId" :options="listDepartment" label="organizationBranchName" :reduce="(department) => department.id"></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          <b-form-group v-if="isSchoolBlock">
            <label class="font-weight-bold font-size-label-custom" for="blockSchool">Phòng ban <span class="text-danger">(*)</span></label>
            <v-select disabled="" placeholder="Chọn phòng ban" v-model="modelSchoolBlock.parentId" :options="listDepartment" label="organizationBranchName" :reduce="(department) => department.id"></v-select>
          </b-form-group>
          <validation-provider #default="{ errors }" name="Tên khối" rules="required">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom" for="blockSchool">Tên khối <span class="text-danger">(*)</span></label>
              <b-form-input id="blockSchool" v-model="modelSchoolBlock.organizationBranchName" placeholder="Nhập tên khối"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </div>
      <div class="footer-modal-manager-school-block">
        <b-button v-if="!isSchoolBlock" class="mr-1" @click="addSchoolBlock"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
        >
          <feather-icon icon="CheckIcon" />
          <span class="align-middle">Tạo mới</span>
        </b-button>
        <b-button v-if="isSchoolBlock" class="mr-1" @click="updateSchoolBlock"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
        >
          <feather-icon icon="CheckIcon" />
          <span class="align-middle">Cập nhật</span>
        </b-button>
        <b-button @click="closeModal"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
        >
          <feather-icon icon="XIcon" />
          <span class="align-middle">Đóng</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BFormInput, VBTooltip, BFormGroup, BModal, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BFormInput,
    vSelect,
    BFormGroup,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [heightTransition],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isSchoolBlock: false,
      modelSchoolBlockSearch: {
        number: 1,
        name: '',
        parentId: null,
      },
      required,
      modelSchoolBlock: {
        parentId: null,
        organizationBranchName: '',
      },
      idDepartment: 1,
      perPage: 10,
      currentPage: 1,
      totalCountPage: 0,
      listSchoolBlock: [],
      listDepartment: [],
      fields: [
        {
          key: 'index', label: 'stt', thClass: 'custom-th-table-index',
        },
        {
          key: 'organizationBranchName', label: 'Tên khối',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-table-action',
        },
      ],
    }
  },
  created() {
    this.getListAllDepartment()
    this.getListSchoolBlock()
  },
  computed: {
    row() {
      return this.listSchoolBlock.length
    },
  },
  methods: {
    getListAllDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    refreshList() {
      this.getListSchoolBlock()
    },
    getListSchoolBlock() {
      this.listSchoolBlock = []
      this.modelSchoolBlockSearch.name = ''
      if (this.idDepartment) {
        this.$crm.post('organization-branch-school/find-children-by-condition', this.modelSchoolBlockSearch).then(res => {
          this.listSchoolBlock = res.data
          this.totalCountPage = this.listSchoolBlock.length
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Cảnh báo',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            text: 'Phòng ban không được để trống',
          },
        })
      }
    },
    getFormAddSchoolBlock() {
      this.isSchoolBlock = false
      this.modelSchoolBlock = {
        departmentId: null,
        organizationBranchName: '',
      }
      this.$refs['modal-school-block'].show()
    },
    addSchoolBlock() {
      this.$refs.modelSchoolBlock.validate().then(success => {
        if (success) {
          this.$crm.post('organization-branch-school/create-group', this.modelSchoolBlock).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo mới khối thành công',
              },
            })
            this.getListSchoolBlock()
            this.$refs['modal-school-block'].hide()
          })
        }
      })
    },
    updateSchoolBlock() {
      this.$refs.modelSchoolBlock.validate().then(success => {
        if (success) {
          this.$crm.post(`organization-branch-school/update-group/${this.idSchoolBlock}`, this.modelSchoolBlock).then(res => {
            this.modelSchoolBlock = res.data
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Cập nhật khối thành công',
              },
            })
            this.$refs['modal-school-block'].hide()
            this.getListSchoolBlock()
          })
        }
      })
    },
    getFormUpdateSchoolBlock(id) {
      this.isSchoolBlock = true
      this.idSchoolBlock = id
      if (id) {
        this.$crm.post(`organization-branch-school/find-group-by-id/${id}`).then(res => {
          this.modelSchoolBlock = res.data
          this.modelSchoolBlock.parentId = this.modelSchoolBlock.departmentId
          this.getListSchoolBlock()
          this.$refs['modal-school-block'].show()
        })
      }
    },
    closeModal() {
      this.$refs['modal-school-block'].hide()
    },
    deleteSchoolBlock(item) {
      this.$swal({
        title: 'Xóa khối',
        text: `Bạn có muốn xóa khối ${item.organizationBranchName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.get(`organization-branch-school/delete-organization-branch/${item.id}`).then(res => {
            this.$swal({
              icon: 'success',
              title: 'Xóa khối',
              text: 'Xóa khối thành công',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getListSchoolBlock()
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../../@core/scss/custom/schoolblock.scss';
</style>
